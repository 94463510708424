import React, { createContext, useContext } from 'react';
import { getText } from '../utils/functions';
import { CompanyContext } from './company.context';
interface ILangContext {
  t: (value: any) => any;
  lang?: string;
}

interface ILangContextProvider {
  lang: string;
  children?: React.ReactNode;
}

export const LangContext = createContext<ILangContext>({
  t: () => {},
});

export const LangContextProvider = ({ lang, children }: ILangContextProvider) => {
  /**
   * Handy method to find a given set of translations, under the current language.
   * This method assumes that a static query is being executed at the component level
   * under this structure:
   * ```
   * query {
   *   allI18NJson {
   *     nodes {
   *       locale
   *       FORM {
   *         CARD_NUMBER_TITLE
   *       }
   *     }
   *   }
   * }
   * ```
   *
   * The passed `data` param should be the resulting object from the original query;
   * from there on, the method traverses through the resulting structure, to find the
   * corresponding node that matches the current language (identified through the
   * `locale` code) passed to the provider.
   *
   * The required string translations can then be referrenced as nested keys to that node:
   * @example
   * ```
   * t(data).FORM.CARD_NUMBER_TITLE
   * ```
   *
   * While this implementation may look a little too verbose, and assume the pre-existence
   * of a static query, it does come with great benefits:
   * - the page or component consuming the i18n query will only request for the exact keys;
   *   it will not retrieve the entire i18n object (as it did on previous version)
   * - the language and this method is provided through context to any piece that needs it;
   *   we don't need to pass along the previous `get` method to its final destination
   *   (prop-drilling), which was a maintainability penalty
   *
   * @param {object} data
   * @returns {object}
   */
  const getClient = useContext(CompanyContext)?.clientId || '0';

  const t = (data: any): object => getText(data.allI18NJson.nodes, lang, getClient);

  return (
    <LangContext.Provider
      value={{
        lang,
        t,
      }}
    >
      {children}
    </LangContext.Provider>
  );
};
