import {
  CardInformation,
  CompanyDetail,
  CustomerInformation,
  CvuInformation,
  CvvInformation,
  SearchInformation,
  SecurityCommandInformation,
  UserDetailInformation,
} from '../models/context';

import {
  BinValidationCard,
  CardBinValidationResponse,
  CardResponse,
  CardPaymentResponse,
  CompanyResponse,
  CompanySearchResponse,
  CustomerResponse,
  CvuRequest,
  CvuResponse,
  CvvResponse,
  DocumentScanResponse,
  ResponseGetTimeStamp,
  SecurityCommandResponse,
  UserDetailInformationResponseAndRequest,
} from '../models/api/response';

import customerJson from '../constants/customer.json';
import { CustomerRequest } from '../models/api/request';
import { isAllKeysHasValidValue, isTrue } from './functions';

const converterGetTimeStamp = ({ current_date }: ResponseGetTimeStamp): string => current_date;

const convertUserDetailToApp = (userDetail: UserDetailInformationResponseAndRequest): UserDetailInformation => ({
  department: userDetail?.customer?.address?.department ?? '',
  documentNumber: userDetail?.customer?.government_identification ?? '',
  documentType: userDetail?.customer?.government_identification_type ?? '',
  email: userDetail?.customer?.email ?? '',
  expirationTime: userDetail?.expiration_time ?? '',
  gender: userDetail?.customer?.gender?.toUpperCase() ?? '',
  landlineNumber: userDetail?.customer?.telephone_number ?? '',
  lastname: userDetail?.customer?.surname ?? '',
  location: userDetail?.customer?.address?.city ?? '',
  name: userDetail?.customer?.name ?? '',
  phoneCompany: userDetail?.customer?.telephone_company?.toUpperCase() ?? '',
  phoneNumber: userDetail?.customer?.cell_phone ?? '',
  postalCode: userDetail?.customer?.address?.zip_code ?? '',
  province: userDetail?.customer?.address?.province ?? '',
  street: userDetail?.customer?.address?.street ?? '',
  streetFloor: userDetail?.customer?.address?.floor ?? '',
  streetNumber: userDetail?.customer?.address?.number ?? '',
  taxNumber: userDetail?.customer?.tribute_key ?? '',
  taxType: userDetail?.customer?.tribute_key_type?.toUpperCase() ?? '',
  remaningTimeToTimeOut: userDetail?.remaning_time_to_timeout ?? 0,
});

const convertFromAppToUserDetail = (userDetail: UserDetailInformation): UserDetailInformationResponseAndRequest => {
  const mapMonth = (month: string) =>
  (({
    'ene.': '01',
    'feb.': '02',
    'mar.': '03',
    'abr.': '04',
    'may.': '05',
    'jun.': '06',
    'jul.': '07',
    'ago.': '08',
    'sep.': '09',
    'oct.': '10',
    'nov.': '11',
    'dic.': '12',
  } as any)[month]);

  const toDate = (str: string) => {
    const newDate = str.split(' ');
    newDate[1] = mapMonth(newDate[1]);
    return `${newDate[2]}-${newDate[1]}-${newDate[0]}`;
  };

  return {
    customer: {
      address: {
        city: userDetail.location ?? '',
        department: userDetail.department?.trim() ?? '',
        floor: userDetail.streetFloor ?? '',
        number: userDetail.streetNumber ?? '',
        province: userDetail.province ?? '',
        street: userDetail.street ?? '',
        zip_code: userDetail.postalCode?.trim() ?? '',
      },
      cell_phone: userDetail.phoneNumber ?? '',
      email: userDetail.email ?? '',
      gender: userDetail.gender ?? '',
      name: userDetail.name ?? '',
      surname: userDetail.lastname ?? '',
      telephone_company: userDetail.phoneCompany ?? '',
      telephone_number: userDetail.landlineNumber?.trim() ?? '',
      tribute_key: userDetail.taxNumber ?? '',
      tribute_key_type: userDetail.taxType ?? '',
      government_identification: userDetail.documentNumber
        ? [userDetail.documentType !== '', userDetail.documentType !== 'PASAPORTE'].every(isTrue)
          ? userDetail.documentNumber?.trim().padStart(8, '0')
          : userDetail.documentNumber?.trim().toLocaleUpperCase()
        : '',
      government_identification_type: userDetail.documentType ?? '',
      nationality: userDetail.nationality ?? '',
      birth_date: (userDetail.birthDate && toDate(userDetail.birthDate)) ?? '',
      biometrics: userDetail.biometrics ?? false,
      ocr: userDetail.ocr ?? false,
    },
  };
};

const convertBinValidationToApp = ({
  code,
  name,
  cvv_size,
  issuers,
  mp_type,
  error_code
}: CardBinValidationResponse): BinValidationCard => ({
  banks: issuers?.map(({ code, short_name }) => ({ code, name: short_name })),
  brand: code,
  name: name,
  cvvSize: cvv_size,
  type: mp_type,
  errorCode: error_code
});

const convertCvvToApp = ({ form_data: { data: { cards: [card] } }, ...meta }: CvvResponse): CvvInformation => ({
  cardId: card.uid,
  cvvSize: card.cvv_size,
  brand: card.payment_method_code,
  cardType: card.payment_method_type,
  paymentMethodName: card.payment_method_name,
  lastFourNumbers: card.last_four_digits,
  expirationTime: meta.expiration_time,
  remaningTimeToTimeOut: meta.remaning_time_to_timeout,
});

const convertCvuToApp = (cvu: CvuResponse): CvuInformation => ({
  documentNumber: cvu?.customer?.government_identification,
  documentType: cvu?.customer?.government_identification_type,
  expirationTime: cvu?.expiration_time ?? '',
  lastname: cvu?.customer?.surname,
  name: cvu?.customer?.name,
  remaningTimeToTimeOut: cvu?.remaning_time_to_timeout ?? '',
  taxNumber: cvu?.customer?.tribute_key,
  taxType: cvu?.customer?.tribute_key_type?.toUpperCase(),
});

const convertFromAppToCvu = (cvu: CvuInformation): CvuRequest => ({
  customer: {
    name: cvu.name !== '' ? cvu.name?.trim() : undefined,
    surname: cvu.lastname !== '' ? cvu.lastname?.trim() : undefined,
    tribute_key: cvu.taxNumber !== '' ? cvu.taxNumber?.trim() : undefined,
    tribute_key_type: cvu.taxType !== '' ? cvu.taxType?.trim() : undefined,
    government_identification:
      cvu.documentNumber !== ''
        ? [cvu.documentType !== '', cvu.documentType !== 'PASAPORTE'].every(isTrue)
          ? cvu.documentNumber?.trim().padStart(8, '0')
          : cvu.documentNumber?.trim().toLocaleUpperCase()
        : undefined,
    government_identification_type: cvu.documentType !== '' ? cvu.documentType?.trim() : undefined,
  },
  alias: cvu.alias !== '' ? cvu.alias?.trim() : undefined,
});

const convertCardInfoToApp = (cardInfo: CardResponse): CardInformation => ({
  expirationTime: cardInfo?.expiration_time ?? '',
  isOrchestation: cardInfo?.is_orchestation ?? '',
  remaningTimeToTimeOut: cardInfo?.remaning_time_to_timeout ?? '',
  origin: cardInfo?.tokenex?.origin ?? '',
  timestamp: cardInfo?.tokenex?.timestamp ?? '',
  tokenScheme: cardInfo?.tokenex?.token_scheme ?? '',
  tokenExID: cardInfo?.tokenex?.tokenex_id ?? '',
  authenticationKey: cardInfo?.tokenex?.authentication_key ?? '',
  paymentMethods: (cardInfo.payment_methods || []).map(pm => ({
    code: pm.payment_method_code,
    name: pm.payment_method_name,
    type: pm.payment_method_type,
  })),
});

const convertCardPaymentInfoToApp = (paymentInfo: CardPaymentResponse): CardInformation => {
  const cardInfo = paymentInfo?.form_data?.data
  return ({
    expirationTime: paymentInfo?.expiration_time ?? '',
    remaningTimeToTimeOut: paymentInfo?.remaning_time_to_timeout ?? '',
    amount: cardInfo?.amount ?? '',
    ota_id: cardInfo?.ota_id ??'',
    paymentMethods: (cardInfo.payment_methods || []).map(pm => ({
      code: pm.payment_method_code,
      name: pm.payment_method_name,
      type: pm.payment_method_type,
    })),
  })
};

const convertCompanySearchToApp = (search: CompanySearchResponse): SearchInformation => {
  const { content, pagination, communication_token } = search;

  return {
    last: pagination?.last,
    communicationToken: communication_token,
    companies: content?.map(({ service_company_name, category_name, service_company_id }) => ({
      name: service_company_name,
      category: category_name,
      serviceCompanyId: service_company_id
    })),
  };
};

const convertCustomerToApp = ({
  intent_chatbot,
  customer,
  expiration_time,
  remaning_time_to_timeout,
}: CustomerResponse): CustomerInformation => {
  return {
    chatbotIntent: intent_chatbot,
    department: customer?.address?.department,
    documentNumber: customer?.government_identification,
    documentType: customer?.government_identification_type,
    email: customer?.email,
    expirationTime: expiration_time ?? '',
    lastname: customer?.surname,
    location: customer?.address?.city,
    name: customer?.name,
    postalCode: customer?.address?.zip_code,
    province: customer?.address?.province,
    remaningTimeToTimeOut: remaning_time_to_timeout,
    street: customer?.address?.street,
    streetNumber: customer?.address?.number,
    taxNumber: customer?.tribute_key,
    taxType: customer?.tribute_key_type,
  };
};

const convertFromAppToCustomer = (customer: CustomerInformation): CustomerRequest => {
  const documentNumber = {
    government_identification:
      customer.documentNumber !== ''
        ? [customer.documentType !== '', customer.documentType !== 'PASAPORTE'].every(isTrue)
          ? customer.documentNumber?.trim().padStart(8, '0')
          : customer.documentNumber?.trim().toLocaleUpperCase()
        : undefined,
  };

  const address = {
    city: customer.location !== '' ? customer.location?.trim() : undefined,
    zip_code: customer.postalCode !== '' ? customer.postalCode?.trim() : undefined,
    province: customer.province !== '' ? customer.province?.trim() : undefined,
    street: customer.street !== '' ? customer.street?.trim() : undefined,
    number: customer.streetNumber !== '' ? customer.streetNumber?.trim() : undefined,
  };

  return {
    customer: {
      name: customer.name !== '' ? customer.name?.trim() : undefined,
      surname: customer.lastname !== '' ? customer.lastname?.trim() : undefined,
      tribute_key: customer.taxNumber !== '' ? customer.taxNumber?.trim() : undefined,
      tribute_key_type: customer.taxType !== '' ? customer.taxType?.trim() : undefined,
      email: customer.email !== '' ? customer.email?.trim() : undefined,
      government_identification_type: customer.documentType !== '' ? customer.documentType?.trim() : undefined,
      ...documentNumber,
      ...(isAllKeysHasValidValue(address) ? { address } : undefined),
    },
  };
};

const convertUserDNIToApp = (user: DocumentScanResponse): UserDetailInformation => ({
  name: user.information.first_name,
  lastname: user.information.last_name,
  gender: user.information.gender === 'M' ? 'MALE' : 'FEMALE',
  birthDate: user.information.birth_date,
  documentNumber: `${user.information.document_number}`,
  location: '',
  postalCode: '',
  province: '',
  street: '',
  streetFloor: '',
  streetNumber: '',
  taxType: '',
  taxNumber: '',
  landlineNumber: '',
  email: '',
});

const convertSecurityToApp = (securityCommand: SecurityCommandResponse): SecurityCommandInformation => ({
  command: securityCommand.command,
  expirationTime: securityCommand.expiration_time,
  remaningTimeToTimeout: securityCommand.remaning_time_to_timeout,
});

const convertCompanyToApp = (companyDetail: CompanyResponse): CompanyDetail => ({
  country: companyDetail?.country ?? '',
  name: companyDetail?.name ?? '',
  telephoneFormat: companyDetail?.telephone_format ?? customerJson.PhoneFormat.TelephoneFormat,
  cellPhoneFormat: companyDetail?.cell_phone_format ?? customerJson.PhoneFormat.CellPhoneFormat,
});

export {
  convertBinValidationToApp,
  convertCardInfoToApp,
  convertCardPaymentInfoToApp,
  convertCompanySearchToApp,
  convertCompanyToApp,
  convertCustomerToApp,
  convertCvuToApp,
  convertCvvToApp,
  converterGetTimeStamp,
  convertFromAppToCustomer,
  convertFromAppToCvu,
  convertFromAppToUserDetail,
  convertSecurityToApp,
  convertUserDetailToApp,
  convertUserDNIToApp,
};
