import { ValidateParams } from '../models/tokenex';

const TOKENEX_API_URL_NON_PROD = 'http://localhost:447349/api/v1/tokenize';
const TOKENEX_API_URL_QA = 'https://qa-aws.waasabi.io/tokenizer/api/v1/tokenize';
const TOKENEX_API_URL_DEV = 'https://dev-aws.waasabi.io/tokenizer/api/v1/tokenize';
const TOKENEX_API_URL_DEMO = 'https://demo-aws.waasabi.io/tokenizer/api/v1/tokenize';
const TOKENEX_API_URL_PROD = 'http://vm-silbo-e4-fc.pago.chat:47349/api/v1/tokenize';

const validCardTokenex = ({ isValid, isCvvValid }: ValidateParams) => {
  if (isValid && isCvvValid) {
    return true;
  }

  return false;
};

const getTokenExUrl = () => {
  // @fixme move this to `browser` or any higher entity, for the sake of re-usability...
  return typeof window !== 'undefined' && (
    (window as any)?.location.host === 'customer.pago.chat' ? TOKENEX_API_URL_PROD :
    (
        (window as any)?.location.host === 'qa-aws.waasabi.io' ? TOKENEX_API_URL_QA :
        (
            (window as any)?.location.host === 'dev-aws.waasabi.io' ? TOKENEX_API_URL_DEV :
                (
                        (window as any)?.location.host === 'demo-aws.waasabi.io' ? TOKENEX_API_URL_DEMO :
            TOKENEX_API_URL_NON_PROD)
            ))
    );
}

export { validCardTokenex, getTokenExUrl };