import React, { useState, createContext } from 'react';
import { parse, subMinutes, format } from 'date-fns';

import {
  CardInformation,
  CompanyDetail,
  CreditCardContextInterface,
  CustomerInformation,
  CvuInformation,
  CvvInformation,
  SearchInformation,
  UserDetailInformation,
} from '../models/context';
import { ContextPropsInterface, TimeStampError, CallServiceAndTimeStamp, ResultServiceWithTimeSatmp } from './models';

import appService from '../services/endpoints';

const callServiceWithTimeStamp = async (service: Promise<any>): Promise<ResultServiceWithTimeSatmp> => {
  let [currentDateTime, serviceCall]: [TimeStampError | string, CallServiceAndTimeStamp] = await Promise.all([
    appService.getTimeStamp(),
    service,
  ]);

  if ((currentDateTime as TimeStampError).status) {
    serviceCall.status = (currentDateTime as TimeStampError).status;
    serviceCall.error = (currentDateTime as TimeStampError).error;
    const newExpirationTime = parse(serviceCall.expirationTime ?? '', 'yyyyMMddHHmmss', new Date());
    const newCurrentTime = subMinutes(newExpirationTime, serviceCall.remaningTimeToTimeOut ?? 0);
    currentDateTime = format(newCurrentTime, 'yyyyMMddHHmmss');
  }

  return {
    datetime: currentDateTime,
    data: serviceCall,
  };
};

export const CreditcardContext = createContext<ContextPropsInterface>({
  setLoading: () => { return; }
});

export const CreditCardContextProvider = ({ children }: CreditCardContextInterface) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [timeStamp, setTimeStamp] = useState<string>('');
  const [infoUser, setData] = useState<
    CardInformation | UserDetailInformation | CvvInformation | CvuInformation | CustomerInformation
  >({});
  const [search, setSearch] = useState<SearchInformation>({});
  const [company, setCompany] = useState<CompanyDetail>({});

  const clearDataState = () => {
    setData({});
  };

  const getCard = async (token: string) => {
    const { datetime, data }: ResultServiceWithTimeSatmp = await callServiceWithTimeStamp(
      appService.getCardInfo({ token })
    );

    setData(data);
    setTimeStamp(datetime as string);
  };

  const getCardPayment = async (token: string) => {
    const { datetime, data }: ResultServiceWithTimeSatmp = await callServiceWithTimeStamp(
      appService.getCardPaymentInfo({ token })
    );

    setData(data);
    setTimeStamp(datetime as string);
  };

  const getUser = async (token: string) => {
    const { datetime, data }: ResultServiceWithTimeSatmp = await callServiceWithTimeStamp(
      appService.getUserDetail({ token })
    );

    setData(data);
    setTimeStamp(datetime as string);
  };

  const updateTimeStamp = async () => {
    const currentDateTime = (await appService.getTimeStamp()) as string;
    setTimeStamp(currentDateTime);
  };

  const getCvv = async (token: string) => {
    const { datetime, data }: ResultServiceWithTimeSatmp = await callServiceWithTimeStamp(
      appService.getCvvinformation({ token })
    );

    setData(data);
    setTimeStamp(datetime as string);
  };

  const getCvu = async (token: string) => {
    const { datetime, data }: ResultServiceWithTimeSatmp = await callServiceWithTimeStamp(
      appService.getCvuInformation({ token })
    );

    setData(data);
    setTimeStamp(datetime as string);
  };

  const getCompanySearch = async (token: string, searchTerm: string, { next, categoryName }: any = {}) => {
    try {
      const { companies, last, communicationToken } = await appService.getCompanySearch({
        token,
        search: searchTerm.toLowerCase(),
        next,
        categoryName,
      });

      setSearch({ companies, last, communicationToken });
    } catch ({ status, error }) {
      throw { status, error };
    }
  };

  const cleanSearchResults = () => appService.cleanSearchResult();

  const resetSearchTerm = () => setSearch({});

  const getCustomer = async (token: string) => {
    const { datetime, data }: ResultServiceWithTimeSatmp = await callServiceWithTimeStamp(
      appService.getCustomer({ token })
    );

    setData(data);
    setTimeStamp(datetime as string);
  };

  const getTokenLock = async (token: string) => {
    const { datetime, data }: ResultServiceWithTimeSatmp = await callServiceWithTimeStamp(
      appService.getTokenLock(token)
    );

    setData(data);
    setTimeStamp(datetime as string);
  };

  const getCompanyDetails = async () => {
    const company = await appService.getCompanyDetails();

    setCompany(company);
  };

  return (
    <CreditcardContext.Provider
      value={{
        company,
        infoUser,
        loading,
        search,
        timeStamp,
        getCard,
        getCardPayment,
        getCompanyDetails,
        getCompanySearch,
        getCustomer,
        getCvu,
        getCvv,
        getTokenLock,
        getUser,
        resetSearchTerm,
        setLoading,
        updateTimeStamp,
        cleanSearchResults,
        clearDataState,
      }}
    >
      {children}
    </CreditcardContext.Provider>
  );
};
