import * as Yup from 'yup';
import { parse, differenceInMonths } from 'date-fns';

Yup.addMethod<Yup.StringSchema>(Yup.string, 'validExpirationDate', function (this: any, errorMessage: string) {
  return this.test('validate-expiration-date', errorMessage, function (this: any, value: string): number {
    const { createError } = this;
    const dateParsed = parse(value, 'MM/yy', new Date());
    const diff = differenceInMonths(dateParsed, new Date());
    return diff >= 0 || createError(errorMessage);
  });
});

Yup.addMethod<Yup.StringSchema>(Yup.string, 'validCuitCuil', function (this: any, errorMessage: string) {
  return this.test('validate-cuit-cuil', errorMessage, function (this: any, cuit: string): boolean {
    const { createError } = this;
    cuit = cuit?.replace(/[^0-9]/g, '');

    if (!cuit) return true;

    if (cuit?.length != 11) {
      return createError(errorMessage);
    }

    let acumulado = 0;
    const digitos: number[] = cuit?.split('').map(number => +number) ?? '';
    const digito: number = digitos.pop() as number;

    for (let i = 0; i < digitos.length; i++) {
      acumulado += digitos[9 - i] * (2 + (i % 6));
    }

    let verif: number = 11 - (acumulado % 11);

    if (verif == 11) {
      verif = 0;
    }

    return digito === verif;
  });
});

Yup.addMethod<Yup.StringSchema>(Yup.string, 'validDocument', function (this: any, errorMessage: string) {
  return this.test('validate-Document', errorMessage, function (this: any, documentNumber: string): boolean {
    const { createError } = this;
    if (documentNumber?.length < 7) {
      return createError(errorMessage);
    } else {
      return true
    }
  });
});