import React from 'react';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';

import './src/styles/global.css'
import './src/styles/fonts.css';
import './src/styles/cookiePopup/cookiePopup.css';

import { get as baseGet } from './src/utils/functions';

import { CreditCardContextProvider } from './src/context/creditcard.context';
import { LangContextProvider } from './src/context/lang.context';
import { CompanyContextProvider } from './src/context/company.context';
import { FeatureContextProvider } from './src/context/feature.context';

import './src/utils/yup';

const CompanyStyles = ({ company }) => {
  const { clientId, getPublicURL } = company;
  
  // we _import_ typography definitions because we want to automatically
  // reference the imported fonts at the public URL
  import(`./src/styles/companies/${clientId}/typography.css`);
  
  // we don't _import_ variables and cookie-popup overrides, though,
  // because we need only one variables file per company at a time,
  // so we load them through old-school `link` tag, on runtime
  return (
    <Helmet>
      <link rel="stylesheet" href={getPublicURL(`/theme/styles/variables.css`)} />
      <link rel="stylesheet" href={getPublicURL(`/theme/styles/cookie-popup.css`)} />
    </Helmet>
  );
}

const wrapRootElement = ({ element }) => (
  <CreditCardContextProvider>
    {element}
  </CreditCardContextProvider>
);

const wrapPageElement = ({ element, props }) => {
  const { search } = props.location;
  const params = queryString.parse(search);
  const getToken = () => Object.keys(params).find(key => params[key] === null);

  const { pageContext } = props;
  const { i18n, lang, company, featureSettings } = pageContext;

  // @todo get rid of `get` for good...
  const get = (key) => {
    return i18n && baseGet(i18n[lang], key);
  };

  if (!!props?.pageContext?.showBuild) {
    if (typeof window !== 'undefined' && !window?.showInfoUserBuildContainer) {
      const styleConsole = `
        background: #000;
        color: #d82b2b;
        padding: 5px;
        font-weight: bold;
        font-size: 20px;
      `;

      console.log(`%c Container Build: ${props?.pageContext?.containerBuildId ?? ''}`, styleConsole);
      console.log(`%c Author Build: ${props?.pageContext?.authorBuild ?? ''}`, styleConsole);
      window.showInfoUserBuildContainer = true;
    }
  }

  return (
    <CompanyContextProvider company={company}>
      <LangContextProvider lang={lang}>
        <FeatureContextProvider featureSettings={featureSettings}>
          {company?.clientId && (
            <CompanyStyles company={company} />
          )}
          {React.cloneElement(element, { ...props, get, params, search, getToken })}
        </FeatureContextProvider>
      </LangContextProvider>
    </CompanyContextProvider>
  );
};

export { wrapRootElement, wrapPageElement };
