import React, { createContext } from 'react';
import { Company, ImageInfo } from '../models/company';

interface ICompanyContextProvider {
  company: Company;
  children?: React.ReactNode;
}

export const CompanyContext = createContext<Company>({
  getPublicURL: () => '',
  cookieConsentURL: '',
  currency: {
    symbol: '$',
    append: false,
  },
  governmentValidationType: "Multiple",
  tributeValidationType: "CUIT-CUIL"
});

export const CompanyContextProvider = ({ company, children }: ICompanyContextProvider) => {
  const { clientId, assets } = company;
  company.getPublicURL = (path: string): string => {
    const url: ImageInfo = assets?.find(({ relativePath }) => {
      return clientId && relativePath === `companies/${clientId}${path}`;
    });
    if (!url) console.warn(`The asset path: ${path} is wrong or asset don't exist for the company ${clientId}`);
    return url?.publicURL;
  };

  return <CompanyContext.Provider value={company}>{children}</CompanyContext.Provider>;
};
