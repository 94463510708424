import get from 'lodash/get';
import memoize from 'lodash/memoize';
import isFunction from 'lodash/isFunction';
import isInteger from 'lodash/isInteger';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import { isValid, differenceInSeconds, parse } from 'date-fns';

const UNIQUE_ID: any = {};

const uniqueId = (prefix = 'pagoChat-') => {
  if (!UNIQUE_ID[prefix]) {
    UNIQUE_ID[prefix] = 0;
  }

  const id = ++UNIQUE_ID[prefix];

  return `${prefix}${id}`;
};

const diffTwoDatesInSeconds = memoize((dateFrom: string, dateUntil: string): number => {
  const parsedTimeStamp = parse(dateFrom, 'yyyyMMddHHmmss', new Date());
  const parsedLimitDateTime = parse(dateUntil, 'yyyyMMddHHmmss', new Date());

  if (!isValid(parsedLimitDateTime) || !isValid(parsedTimeStamp)) {
    return 0;
  }

  return differenceInSeconds(parsedLimitDateTime, parsedTimeStamp);
});

const isTrue = (value: any) => value === true;
const isGreaterThan = (greater: number) => (value: any) => isInteger(value) && value > greater;
const isEqual = (equal: number) => (value: any) => isInteger(value) && value === equal;
const delay = (time: number) => new Promise<void>(resolve => setTimeout(() => resolve(), time));
const isNotUndefined = (value: any) => !isUndefined(value);
const isAllKeysHasValidValue = (object: any) => {
  const resultObject: any = {};
  const keys: string[] = Object.keys(object);
  keys.forEach((key: string) =>
    [undefined, null].every((type: any) => object[key] !== type) ? (resultObject[key] = object[key]) : null
  );
  return !isEmpty(resultObject);
};

const replaceAll = (str: string, find: string, replace: string) => {
  const escapedFind = find?.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
  return str?.replace(new RegExp(escapedFind, 'g'), replace);
};

const getText = (nodes: any, lang: string | undefined, getClient: any) => {
  return nodes.find(
    ({ locale, clientId }: { locale: string; clientId: string }) =>
      clientId && clientId === getClient && locale && locale.indexOf(lang || '') !== -1
  );
};

const isConsecutive = (str: string): boolean => !!str && str.split('').every(
    (item, index, array) =>
        index + 1 < array.length ? (parseInt(array[index + 1]) - 1) === parseInt(item, 10) : true
);

const isConsecutiveDown = (str: string): boolean => !!str && str.split('').every(
    (item, index, array) =>
        index + 1 < array.length ? (parseInt(array[index + 1]) + 1) === parseInt(item, 10) : true
);

const isRepeated = (str: string) => /(\d)\1\1\1/.test(str);

export {
  delay,
  diffTwoDatesInSeconds,
  get,
  isAllKeysHasValidValue,
  isEmpty,
  isEqual,
  isFunction,
  isGreaterThan,
  isNotUndefined,
  isTrue,
  isUndefined,
  memoize,
  replaceAll,
  uniqueId,
  getText,
  isConsecutive,
  isConsecutiveDown,
  isRepeated,
};
