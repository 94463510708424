import React, { createContext } from 'react';
import { FeatureSettings } from '../models/feature';
interface IFeatureContextProvider {
  featureSettings: FeatureSettings;
  children?: React.ReactNode;
}

export const FeatureContext = createContext<FeatureSettings>({
  settings: {},
});

export const FeatureContextProvider = ({ featureSettings, children }: IFeatureContextProvider) => {
  return <FeatureContext.Provider value={featureSettings}>{children}</FeatureContext.Provider>;
};
